<template>
  <div class="context">
    <!-- 交易概览 -->
    <overview :showCheck="false"></overview>
    <!-- 横向滚动卡片组 -->
    <card-grid></card-grid>
    <ChartLine />
    <Compare @transfer="getUser" />
    <Compare1
      :date="transfer"
      :key="random + 'h'"
      v-if="transfer.dateType"
    ></Compare1>
    <Compare2
      :date="transfer"
      :key="random + 'j'"
      v-if="transfer.dateType"
    ></Compare2>
    <Compare4
      :date="transfer"
      :key="random + 'b'"
      v-if="transfer.dateType"
    ></Compare4>
  </div>
</template>

<script>
import Overview from "./components/overview.vue";
import CardGrid from "./components/cardgrid_3.vue";
import ChartLine from "./components/chart_line";
import Compare from "./components/compare";
import Compare1 from "./components/compare1";
import Compare2 from "./components/compare2";
import Compare4 from "./components/compare4";
export default {
  components: {
    Overview,
    CardGrid,
    ChartLine,
    Compare,
    Compare1,
    Compare2,
    Compare4,
  },
  data() {
    return {
      chartTypes: [1, 2, 3, 4],
      transfer: {},
      random: 0,
    };
  },
  methods: {
    getUser(msg) {
      this.transfer = msg;
      this.random = Math.random();
    },
  },
};
</script>