<template>
  <div class="select-card">
    <div class="select-card-top">
      <div class="title">费用概况</div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-left">
        <!-- 数据维度 -->
        <el-cascader
          collapse-tags
          v-model="select_data_tree"
          :options="data_tree"
          :props="{
            multiple: true,
            label: 'deptName',
            value: 'deptIdOrOaAccount',
            checkStrictly: true,
            emitPath: false,
          }"
          placeholder="请选择组织架构"
          size="small"
          style="min-width: 160px"
          ref="cascader_jg"
          @change="change"
          show-all-levels
          clearable
        >
          <template #default="{ data }">
            <span>{{ data.deptName }}</span>
          </template>
        </el-cascader>
        <!-- 费用类型 -->
        <el-cascader
          collapse-tags
          v-model="selectClass"
          :options="costType_op"
          :props="{
            multiple: true,
            label: 'name',
            value: 'name',
            checkStrictly: true,
          }"
          placeholder="请选择费用类型"
          size="small"
          style="min-width: 160px; margin-left: 12px"
          ref="cascader_type"
          @change="changeCostType"
        >
          <template #default="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
        <el-select
          v-model="costCenterItemCodeList"
          collapse-tags
          placeholder="请选择项目"
          multiple=""
          size="small"
          style="margin-left: 12px"
        >
          <el-option
            v-for="item in costItemType_op"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-card-bottom-right">
        <span style="color: #3baf61; font-size: 16px">月</span>
        <!-- <el-select
          v-model="timeType"
          placeholder="请选择"
          size="small"
          style="width: 100px; margin-left: 12px"
        >
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <div v-show="timeType == 1">
          <el-date-picker
            class="select-day"
            style="margin-left: 24px; margin-right: 20px"
            format="yyyy-MM-dd"
            v-model="timeDay"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions1"
            size="small"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 2" class="week-box">
          <el-date-picker
            class="week-input1"
            style="margin-left: 20px; margin-right: 20px"
            v-model="timeWeekStart"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择开始周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
          <el-date-picker
            class="week-input2"
            style="margin-right: 20px"
            v-model="timeWeekEnd"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择结束周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions3"
            size="small"
            @change="checkTimeType()"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="querySelect"
          :loading="isLoading"
          >查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
export default {
  data() {
    return {
      isTax: 2,
      downType: "",
      isTaxCheck: false,
      testValue: [],
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.lazyLoad,
      },
      downOptions: [
        {
          value: 1,
          label: "大区目标",
        },
        {
          value: 2,
          label: "人的目标",
        },
        {
          value: 3,
          label: "产品线目标",
        },
      ],
      dateOptions: [
        {
          value: 1,
          label: "日",
        },
        {
          value: 2,
          label: "周",
        },
        {
          value: 3,
          label: "月",
        },
      ],
      // 日
      pickerOptions1: {
        disabledDate(date) {
          return date.getTime() >= Date.now() - 60 * 60 * 24 * 1000;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 周
      pickerOptions2: {
        firstDayOfWeek: 1,
        disabledDate(date) {
          return date.getTime() >= Date.now();
        },
      },
      // 月
      pickerOptions3: {
        disabledDate(date) {
          let t = new Date().getDate();
          return date.getTime() > Date.now() - 8.64e7 * t;
        },
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeType: 3, // 日期类型
      timeDay: "", // 选中日
      timeWeekStart: "", // 选中周
      timeWeekEnd: "",
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
      unSelectDept: [], //未选中的架构
      levelArr: [], //
      doctors: [],
      doctorIds: [],
      changeType: false,
      costType_op: [], //费用类型
      costItemType_op: [], //费用项目类型
      costCenterItemCodeList: [],
      departSearchList: [],
      costTypeSearchList: [],
    };
  },
  created() {
    // var tMonth = new Date().getMonth() + 1;
    //  获取交易概览选择列表
    const end = new Date() - 60 * 60 * 24 * 1000;
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    var tMonth = new Date().getMonth();
    // console.log("月份", tMonth);
    this.timeDay = [start, end];
    this.axios.post("TRANSACTION_LIST", { type: 2 }).then((result) => {
      // console.log("获取交易概览选择列表", result);
      if (result.retCode == "200") {
        // 重新组装结构
        let data_tree = [];
        if ("专员直单" in result.data.team) {
          data_tree.push({
            deptIdOrOaAccount: 1,
            deptName: "专员直单",
            children: result.data.team["专员直单"],
          });
        }
        if ("自然人" in result.data.team) {
          data_tree.push(result.data.team["自然人"]);
        }
        if ("经理直单" in result.data.team) {
          data_tree.push(result.data.team["经理直单"]);
        }
        this.data_tree = data_tree;
        this.productClass = result.data.goodsType;
        this.doctors = result.data.doctors;
      } else {
        this.$message.error(result.msg);
      }
    });
    this.defaultDate();
    // 默认调用一下
    this.querySelect();
    this.getCostType();
    this.getCostItemType();
  },
  methods: {
    defaultDate() {
      //获取新的时间
      let date = new Date();
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString();
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month =
        date.getMonth()  < 10
          ? "0" + (date.getMonth() ).toString()
          : (date.getMonth()).toString();
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let da =
        date.getDate() < 10
          ? "0" + date.getDate().toString()
          : date.getDate().toString();
      //字符串拼接，开始时间，结束时间
      let end = year + "-" + month; //当天
      let beg = year + "-" + month; //当月第一天
      this.timeMonth = [beg, end]; //将值设置给插件绑定的数据
    },
    //费用类型
    getCostType() {
      let url = "COST_GETALLCOSTTYPE";
      this.axios
        .get(url, "")
        .then((result) => {
          console.log("查询返回", result);
          if (result.retCode == "200") {
            this.costType_op = result.data;
          } else {
            this.$message.error(result.msg);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    //项目类型
    getCostItemType() {
      let url = "COST_GETCOSTCENTERITEMLIST";
      this.axios
        .get(url, "")
        .then((result) => {
          console.log("查询返回", result);
          if (result.retCode == "200") {
            this.costItemType_op = result.data;
          } else {
            this.$message.error(result.msg);
          }
        })
        .catch(() => {
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        });
    },
    // 切换日期类型
    checkTimeType(index) {
      this.changeType = true;
    },
    // 查询数据
    querySelect() {
      if (this.timeType == 1) {
        if (this.timeDay) {
          let startTime = new Date(this.timeDay[0]).getTime();
          let endTime = new Date(this.timeDay[1]).getTime();
          this.startTime = this.formatDate(startTime)
            .replace("-", "")
            .replace("-", "");
          this.endTime = this.formatDate(endTime)
            .replace("-", "")
            .replace("-", "");
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 2) {
        if (this.timeWeekStart && this.timeWeekEnd) {
          let moment = require("moment");
          this.startTime = moment(this.timeWeekStart)
            .utcOffset(480)
            .format("yyyyWW");
          let year1 = this.startTime.toString().slice(0, 4);
          let week1 = Number(this.startTime.toString().slice(4));
          this.startTime = year1 + week1;

          this.endTime = moment(this.timeWeekEnd)
            .utcOffset(480)
            .format("yyyyWW");
          let year2 = this.endTime.toString().slice(0, 4);
          let week2 = Number(this.endTime.toString().slice(4));
          this.endTime = year2 + week2;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 3) {
        if (this.timeMonth && this.changeType) {
          console.log(this.timeMonth);
          // 年
          let year0 = this.timeMonth[0].getFullYear();
          // 月
          let month0 = this.timeMonth[0].getMonth() + 1;
          // 年
          let year1 = this.timeMonth[1].getFullYear();
          // 月
          let month1 = this.timeMonth[1].getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
          this.endTime = `${year1}${month1 < 10 ? "0" + month1 : month1}`;
        } else {
          this.startTime = this.timeMonth[0].replace(/\-/g, "");
          this.endTime = this.timeMonth[1].replace(/\-/g, "");
        }
      }
     
      this.departSearchList = this.departSearchList.filter((element, index) => {
        return element.departCodes.length > 0;
      });
      this.costTypeSearchList = this.costTypeSearchList.filter(
        (element, index) => {
          return element.costTypeNames.length > 0;
        }
      );
      let params = {
        costCenterItemCodeList: this.costCenterItemCodeList,
        costTypeSearchList: this.costTypeSearchList,
        departSearchList: this.departSearchList,
        end: this.endTime,
        start: this.startTime,
        timeType: this.timeType,
      };
      console.log(params);
      // return;
      this.axios
        .post("COST_SUMMARY", params)
        .then((result) => {
          console.log("查询返回费用", result);
          if (result.retCode == "200") {
            this.$store.dispatch("data/setTotalDataCostZ", result.data);
          } else {
            this.$message.error(result.msg);
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
    //费用类型改变
    changeCostType(val) {
      this.$nextTick(() => {
        let nodesObj = this.$refs["cascader_type"].getCheckedNodes();
        let typeOne = {
            costTypeNames: [],
            level: 1,
          },
          typeTwo = {
            costTypeNames: [],
            level: 2,
          };
        if (nodesObj.length > 0) {
          nodesObj.forEach((element, index) => {
            if (element.level == 1) {
              typeOne.costTypeNames.push(element.value);
            }
            if (element.level == 2) {
              typeTwo.costTypeNames.push(element.value);
            }
          });
        }
        this.costTypeSearchList = [typeOne, typeTwo];
      });
    },

    //组织架构选择
    change() {
      this.$nextTick(() => {
        this.departSearchList = [];
        let nodesObj = this.$refs["cascader_jg"].getCheckedNodes();
        console.log(nodesObj, "123");

        let nodesObjFiter = nodesObj.filter((element, index) => {
          console.log(element.checked, element);
          return element.checked == true;
        });
        console.log(nodesObjFiter, "nodesObjFiter", this.select_data_tree);
        let deptOne = {
            departCodes: [],
            level: 1,
          },
          deptTwo = {
            departCodes: [],
            level: 2,
          },
          deptThree = {
            departCodes: [],
            level: 3,
          },
          deptFour = {
            departCodes: [],
            level: 4,
          },
          deptFive = {
            departCodes: [],
            level: 5,
          };
        if (nodesObj.length > 0) {
          nodesObj.forEach((element, index) => {
            console.log(element);
            if (element.level == 1 && element.checked) {
              deptOne.departCodes.push(element.value);
            }
            if (element.level == 2) {
              deptTwo.departCodes.push(element.value);
            }
            if (element.level == 3) {
              deptThree.departCodes.push(element.value);
            }
            if (element.level == 4) {
              deptFour.departCodes.push(element.value);
            }
            if (element.level == 5) {
              deptFive.departCodes.push(element.value);
            }
          });
        }
        this.departSearchList = [
          deptOne,
          deptTwo,
          deptThree,
          deptFour,
          deptFive,
        ];
      });
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .btnClass {
    width: 100%;
    height: 28px;
    background: rgba(0, 178, 88, 0.1);
    border-radius: 16px;
    color: #00b258;
    border: none;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
      line-height: 28px;
      text-align: center;
    }
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex: 1;
    }
    /deep/ .el-checkbox__label {
      display: inline-block;
      padding-left: 10px;
      line-height: 19px;
      font-size: 12px;
    }
    .check_con {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 17px;
      letter-spacing: 1px;
      margin-right: 24px;
    }
    .btn {
      // margin-right: 12px;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>